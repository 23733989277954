<template>
  <v-dialog
    :value="show"
    persistent
    width="500"
    @click:outside="handleClickOutside"
    @keydown="handleKeydown"
  >
    <v-card>
      <v-form v-model="form.valid" @submit.prevent="save">
        <v-card-title>Verbindungseinstellungen</v-card-title>

        <v-card-text>
          <v-text-field
            autocomplete="off"
            class="validation"
            clearable
            clear-icon="$delete"
            label="Server"
            required
            v-model="server"
            :loading="connecting"
            :rules="form.rules.server"
            @input="checkServerConnectivityDebounced"
          ></v-text-field>

          <p>
            {{ $t("connection.state") }}:

            <v-progress-circular
              indeterminate
              color="primary"
              v-if="connecting"
              :size="24"
            ></v-progress-circular>

            <v-icon
              role="status"
              v-else
              :title="connected ? $t('connection.connected') : $t('connection.disconnected')"
            >
              {{
                connected ? "$approve" : "$close_red"
              }}
            </v-icon>

            <temeno-circle-button
              action
              title="Verbindung erneut prüfen"
              :disabled="connecting || !server"
              @click="checkServerConnectivity(true)"
            >
              $replay
            </temeno-circle-button>
          </p>

          <v-simple-table class="mt-3" dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th>Client-Version</th>
                  <td>{{ versions.client }}</td>
                </tr>
                <tr>
                  <th>Server-Version</th>
                  <td v-if="versions.server">{{ versions.server }}</td>
                  <td v-else class="text--disabled">unbekannt</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <temeno-circle-button
            action
            title="abbrechen"
            v-if="showCancelButton"
            @click="handleClickCancelButton"
          >
            $close
          </temeno-circle-button>

          <temeno-circle-button
            action
            title="speichern"
            type="submit"
            :disabled="!form.valid"
          >
            {{ !form.valid ? '$approve_action_disabled' : '$approve' }}
          </temeno-circle-button>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import _debounce from "lodash/debounce";
import { version } from "@/../package.json";
import { getServerBaseUrl } from "@/functions";
import api from "@/api";

export default {
  name: "ConnectionDialog",
  components: {
    TemenoCircleButton: () => import("@/components/TemenoCircleButton"),
  },
  props: {
    show: {
      type: Boolean,
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      connected: false,
      connecting: false,
      form: {
        rules: {
          server: [
            (v) => (v && v.length > 0) || this.$t("validation.mandatory"),
          ],
        },
        valid: false,
      },
      server: null,
      versions: {
        client: version,
        server: null,
      },
    };
  },
  watch: {
    show(value) {
      if (value) {
        const { server } = this.$store.state.connection;
        this.server = server;
        this.checkServerConnectivity();
      }
    },
  },
  created() {
    this.checkServerConnectivityDebounced = _debounce(
      this.checkServerConnectivityDebounced,
      500
    );
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    checkServerConnectivityDebounced() {
      this.checkServerConnectivity();
    },
    checkServerConnectivity(userInitiated = false) {
      if (this.connecting) {
        return;
      } else if (!this.server) {
        this.connected = false;
        this.versions.server = null;
        return;
      }
      this.connecting = true;
      const serverBaseUrl = getServerBaseUrl(this.server);
      api
        .getConfig(serverBaseUrl)
        .then((response) => {
          this.connected = true;
          this.versions.server = response && response.data && response.data.version;
          if (userInitiated) {
            this.$store.dispatch("snackbar/addSuccess", {
              message: this.$t("connection.connection_succeeded"),
              timeout: 3000,
            });
          }
        })
        .catch(() => {
          this.connected = false;
          this.versions.server = null;
          if (userInitiated) {
            this.$store.dispatch(
              "snackbar/addError",
              this.$t("connection.connection_failed")
            );
          }
        })
        .finally(() => {
          this.connecting = false;
        });
    },
    handleClickCancelButton() {
      this.cancel();
    },
    handleClickOutside() {
      if (!this.showCancelButton) {
        return;
      }
      this.cancel();
    },
    handleKeydown(key) {
      if (key.code === "Escape") {
        // treat Esc like click outside
        this.handleClickOutside();
      }
    },
    save() {
      this.$store.dispatch("connection/setServer", this.server);
      this.$emit("save");
    },
  },
  i18n: {
    messages: {
      de: {
        validation: {
          mandatory: "Dies ist ein Pflichtfeld",
        },
        connection: {
          state: "Verbindungsstatus",
          connected: "verbunden",
          disconnected: "nicht verbunden",
          connection_succeeded:
            "Die Verbindung konnte erfolgreich hergestellt werden.",
          connection_failed: "Die Verbindung ist fehlgeschlagen.",
        },
      },
    },
  },
};
</script>
